import React from 'react';
import Img from 'gatsby-image';
import Lightbox, { useLightbox } from './lightbox';
import './gallery.scss';

const Gallery = props => {
  const { images } = props;
  const { isOpen, initialSlide, openLightbox, closeLightbox } = useLightbox();

  if (!images || images.length <= 0) {
    return null;
  }

  return (
    <>
      <div className="project-gallery">
        {images.map((img, i) => {
          if (img.localFile)
            return (
              <button
                key={img.id}
                className="du__gallery-item"
                role="button"
                onClick={e => {
                  openLightbox(i);
                }}
                onKeyDown={e => {
                  if (e.keyCode === 32 || e.keyCode === 13) openLightbox(i);
                }}
              >
                <Img
                  className="du__gallery-image"
                  fixed={img.localFile.childImageSharp.fixed}
                  style={{
                    width: '100%',
                    height: 'auto',
                    paddingTop: '63.1428571%',
                  }}
                />
              </button>
            );
          return null;
        })}
      </div>
      {isOpen && (
        <Lightbox
          images={images}
          initialSlide={initialSlide}
          closeLightbox={closeLightbox}
        />
      )}
    </>
  );
};

export default Gallery;
