import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import Swiper from 'react-id-swiper';
import Img from 'gatsby-image';
import 'swiper/css/swiper.css';

const Portal = ({ children }) => {
  let modalRoot = document.getElementById('lightbox');

  if (!modalRoot) {
    modalRoot = document.createElement('div');
    modalRoot.setAttribute('id', 'lightbox');
    document.body.appendChild(modalRoot);
  }

  const modalElement = document.createElement('div');

  useEffect(() => {
    modalRoot.appendChild(modalElement);
    return () => modalRoot.removeChild(modalElement);
  });

  return createPortal(children, modalElement);
};

export const useLightbox = () => {
  const [isOpen, setLightboxOpen] = useState(false);
  const [initialSlide, setInitialSlide] = useState(0);

  const openLightbox = index => {
    document.body.classList.add('locked');
    setLightboxOpen(true);
    setInitialSlide(index);
  };

  const closeLightbox = () => {
    document.body.classList.remove('locked');
    setLightboxOpen(false);
  };

  return { isOpen, initialSlide, openLightbox, closeLightbox };
};

const Lightbox = ({ images, closeLightbox, initialSlide = 0 }) => {
  const lightboxRef = React.createRef();
  const params = {
    slidesPerView: 'auto',
    spaceBetween: 20,
    a11y: false,
    slideToClickedSlide: true,
    initialSlide,
    pagination: {
      el: '.swiper-dots',
      type: 'bullets',
      clickable: true,
      dynamicBullets: true,
      dynamicMainBullets: 3,
    },
    breakpoints: {
      720: {
        slidesPerView: 1,
        spaceBetween: 40,
      },
    },
    renderPagination: () => {
      return <span className="lightbox swiper-dots"></span>;
    },
    on: {
      init: function() {
        setTimeout(() => {
          this.update();
        }, 0);
      },
    },
  };

  const keyCloseLightbox = e => {
    if (e.keyCode === 27) closeLightbox();
  };

  useEffect(() => {
    window.addEventListener('keydown', keyCloseLightbox);

    return () => window.removeEventListener('keydown', keyCloseLightbox);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeLightbox]);

  useEffect(() => {
    const el = lightboxRef.current;
    const ani = el.animate([{ opacity: 0 }, { opacity: 1 }], 250);
    ani.onfinish = () => {
      el.style.opacity = '';
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Portal>
      <div ref={lightboxRef} className="du__lightbox" style={{ opacity: 0 }}>
        <button
          className="du__lightbox-close"
          value="close"
          onClick={closeLightbox}
        >
          &times;
        </button>
        <Swiper {...params}>
          {images.map(img => (
            <div key={img.id} className="lightbox-slide">
              <Img
                style={{ height: '100%', width: '100%' }}
                fluid={img.localFile?.childImageSharp.fluid}
              />
            </div>
          ))}
        </Swiper>
        <div
          className="du__lightbox-background-close"
          onClick={closeLightbox}
        ></div>
      </div>
    </Portal>
  );
};

export default Lightbox;
