import React, { Fragment } from 'react';
import { graphql, Link } from 'gatsby';
import Helmet from 'react-helmet';
import parse from 'html-react-parser';
import { Cover } from '~/components/cover';
import ProjectGallery from '~/components/project-gallery';

import './project.scss';

const ProjectTemplate = ({
  data: {
    post: {
      title,
      content,
      image,
      projectTypes,
      industries,
      seo: { title: metaTitle, metaDesc },
      acf: { location, year, status, units, gallery },
    },
  },
  location: browserLocation,
}) => {
  const heroContent = `
      <h1>${title}</h1>
      <h3>${location}${year && ' | '}${year}</h3>
    `;
  const heroData = { content: heroContent, bgImage: image };

  const handleBackClick = e => {
    if (browserLocation.state.fromPortfolio) {
      e.preventDefault();
      window.history.back();
    }
  };

  return (
    <>
      <Helmet>
        <title>{metaTitle || title}</title>
        {metaDesc && <meta name="description" content={metaDesc}></meta>}
      </Helmet>
      <article className="du__work">
        <Cover data={heroData}>{parse(heroContent)}</Cover>

        <div className="crumbs">
          <div className="container">
            <Link
              className="label back-to-portfolio"
              to="/portfolio"
              onClick={handleBackClick}
            >
              &laquo; View All
            </Link>
          </div>
        </div>

        <section className="project-content">
          <div className="container">
            <div className="project-details">
              <div className="project-title">
                <h3 dangerouslySetInnerHTML={{ __html: title }} />
              </div>

              {content && (
                <div
                  className="project-description"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              )}

              <div className="project-meta meta">
                {location && (
                  <div className="meta-item">
                    <span className="label">Location</span>
                    {location}
                  </div>
                )}
                {projectTypes && (
                  <div className="meta-item">
                    <span className="label">Service</span>
                    {projectTypes.map((item, i) => {
                      return (
                        <Fragment key={item.id}>
                          {item.name}
                          {++i < projectTypes.length && ', '}
                        </Fragment>
                      );
                    })}
                  </div>
                )}
                {industries && (
                  <div className="meta-item">
                    <span className="label">Industry</span>
                    {industries.map((item, i) => {
                      return (
                        <Fragment key={item.id}>
                          {item.name}
                          {++i < industries.length && ', '}
                        </Fragment>
                      );
                    })}
                  </div>
                )}
                {(year || status === 'in_progress') && (
                  <div className="meta-item">
                    <span className="label">Year Completed</span>
                    {status === 'in_progress' ? 'In Progress' : year}
                  </div>
                )}
                {units && (
                  <div className="meta-item">
                    <span className="label">Units</span>
                    {units}
                  </div>
                )}
              </div>
            </div>

            <ProjectGallery images={gallery} />
          </div>
        </section>
      </article>
    </>
  );
};

export default ProjectTemplate;

export const projectQuery = graphql`
  query projectQuery($id: String!) {
    post: wordpressWpWork(id: { eq: $id }) {
      title
      content
      seo: yoast_meta {
        title: yoast_wpseo_title
        metaDesc: yoast_wpseo_title
      }
      acf {
        year
        location
        status
        units
        gallery {
          id
          alt: alt_text
          localFile {
            childImageSharp {
              fixed(width: 350, height: 221, quality: 30) {
                ...GatsbyImageSharpFixed
              }
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      image: featured_media {
        localFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      projectTypes: project_types {
        name
        slug
        wordpress_id
      }
      industries {
        name
        slug
        wordpress_id
      }
    }
  }
`;
